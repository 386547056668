import store from '@/store';
export const diller = {
  data() {
    return {
      sabit_diller: [
        {
          lang: 'tr',
          title: 'Türkçe',
        },
        {
          lang: 'en',
          title: 'İngilizce',
        },
        {
          lang: 'de',
          title: 'Almanca',
        },
        {
          lang: 'ru',
          title: 'Rusça',
        },
      ],
      diller: [],
      default_dil: 'tr',
    };
  },
  computed: {
    getDefaultDil() {
      return this.default_dil;
    },
  },
  created() {
    this.handlerGetDiller();
  },
  methods: {
    handlerGetDiller() {
      this.diller = store.getters.getDilAyarlari.diller;
      this.default_dil = store.getters.getDilAyarlari.varsayilan;
    },
  },
};
